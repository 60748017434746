import React, { useCallback, useEffect } from 'react';
import { Button, ButtonPriority } from 'wix-ui-tpa/cssVars';
import type { VerticalStatusContent } from '@wix/pricing-plans-router-utils';
import { Trans, useEnvironment, useTranslation, usePanorama } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { withClientSideRenderMark } from '../../../../contexts/ClientSideRenderMark';
import { useLocale } from '../../../../hooks/useLocale';
import { shortDate, ymdToDate } from '../../../../utils/date';
import { toError } from '../../../../utils/errors';
import { openBillingPage } from '../../../../utils/openBillingPage';
import { getPageType, PageType } from '../../../../utils/pageType';
import type { ThankYouWidgetProps } from '../../../ThankYou/ThankYouWidgetController';
import { FocusedHeading } from '../FocusedHeading';
import { classes } from './Status.st.css';

const RicosViewer = loadable(() => import('./Ricos'));

const Status: React.FC<ThankYouWidgetProps> = (props) => {
  const renderPage = () => {
    switch (getPageType(props, props.integrationData)) {
      default:
      case PageType.DEFAULT_SUCCESS:
        return <Success {...props} />;
      case PageType.ERROR:
        return <Error error={props.translatedError} onButtonClick={props.navigateBackToCheckout} />;
      case PageType.OWNER_DEMO:
        return <OwnerDemo {...props} />;
      case PageType.VERTICAL_SUCCESS:
        return <VerticalSuccess {...props} />;
    }
  };

  return (
    <div data-hook="status-page" className={classes.wrapper}>
      {renderPage()}
    </div>
  );
};

export default withClientSideRenderMark(Status);

interface SuccessProps {
  title: string;
  message?: object | string;
  startDate?: string;
  cta: string;
  onCtaClick(): void;
  ctaDisabled?: boolean;
}

const Success: React.FC<ThankYouWidgetProps> = ({
  integrationData: { verticalStatusContent },
  settings,
  startDate,
  navigateFromStatusPage,
  biThankYouPageCtaButtonClick,
  biThankYouPageOnLoad,
  isOrderProvisioned,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const panorama = usePanorama();

  useEffect(() => {
    biThankYouPageOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseMessage = useCallback(
    (message?: string) => {
      if (message) {
        try {
          return JSON.parse(message);
        } catch (e) {
          panorama.errorMonitor().reportError(toError(e));
        }
      }
    },
    [panorama],
  );

  const props: SuccessProps = React.useMemo(
    () => ({
      title: verticalStatusContent?.titleText ?? settings?.title ?? t('sp.success-title'),
      startDate,
      message: verticalStatusContent?.contentText ?? parseMessage(settings?.message),
      cta: isOrderProvisioned
        ? verticalStatusContent?.buttonText ?? settings?.buttonText ?? t('sp.success-action')
        : t('sp.processing'),
      onCtaClick: () => {
        biThankYouPageCtaButtonClick();
        navigateFromStatusPage();
      },
      ctaDisabled: !isOrderProvisioned,
    }),
    [
      verticalStatusContent,
      settings,
      t,
      startDate,
      parseMessage,
      isOrderProvisioned,
      biThankYouPageCtaButtonClick,
      navigateFromStatusPage,
    ],
  );
  return React.createElement(isMobile ? SuccessMobile : SuccessDesktop, props);
};

const SuccessMobile: React.FC<SuccessProps> = ({ title, startDate, cta, onCtaClick, message, ctaDisabled }) => {
  return (
    <div>
      <FocusedHeading as="h2" data-hook="status-success-title" className={classes.titleMobile}>
        {title}
      </FocusedHeading>
      <WrappedSuccessText className={classes.textMobile} startDate={startDate} message={message} />
      <div className={classes.buttonsContainer}>
        <Button
          data-hook="status-success-button"
          onClick={onCtaClick}
          className={classes.buttonMobile}
          disabled={ctaDisabled}
          priority={ButtonPriority.primary}
          upgrade
        >
          {cta}
        </Button>
      </div>
    </div>
  );
};

const SuccessDesktop: React.FC<SuccessProps> = ({ title, startDate, cta, onCtaClick, message, ctaDisabled }) => {
  return (
    <div>
      <FocusedHeading data-hook="status-success-title" className={classes.titleDesktop}>
        {title}
      </FocusedHeading>
      <WrappedSuccessText className={classes.textDesktop} startDate={startDate} message={message} />
      <div className={classes.buttonsContainer}>
        <Button
          data-hook="status-success-button"
          onClick={onCtaClick}
          className={classes.buttonDesktop}
          disabled={ctaDisabled}
          priority={ButtonPriority.primary}
          upgrade
        >
          {cta}
        </Button>
      </div>
    </div>
  );
};

type SuccessTextProps = Pick<SuccessProps, 'startDate' | 'message'> & { className: string };

const WrappedSuccessText: React.FC<SuccessTextProps> = React.memo(({ startDate, message, className }) => {
  if (message === '') {
    return null;
  }
  return (
    <div className={className} data-hook="status-success-text">
      <SuccessText startDate={startDate} message={message} />
    </div>
  );
});

const SuccessText: React.FC<Pick<SuccessProps, 'startDate' | 'message'>> = ({ startDate, message }) => {
  if (typeof message === 'string') {
    return <p>{message}</p>;
  }
  const defaultText = <DefaultSuccessText startDate={startDate} />;
  if (message) {
    return <RicosViewer message={message} fallbackMessage={defaultText} />;
  }
  return <p>{defaultText}</p>;
};

const DefaultSuccessText: React.FC<Pick<SuccessProps, 'startDate'>> = ({ startDate }) => {
  const { locale } = useLocale();
  const { t } = useTranslation();

  return startDate
    ? t('sp.success-text-with-date', {
        date: shortDate(ymdToDate(startDate), locale),
        interpolation: { escapeValue: false },
      })
    : t('sp.success-text');
};

interface ErrorProps {
  error?: string;
  onButtonClick(): void;
}

const Error: React.FC<ErrorProps> = ({ error, onButtonClick }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const onClick = useCallback(() => onButtonClick(), [onButtonClick]);
  return (
    <div>
      <FocusedHeading data-hook="status-error-title" className={isMobile ? classes.titleMobile : classes.titleDesktop}>
        {error || t('sp.error-title')}
      </FocusedHeading>
      <Button
        data-hook="status-error-button"
        className={isMobile ? classes.buttonMobile : classes.buttonDesktop}
        onClick={onClick}
        priority={ButtonPriority.primary}
        upgrade
      >
        {t('sp.error-action')}
      </Button>
    </div>
  );
};

interface OwnerDemoProps {
  title: string;
  body: React.ReactNode;
  cta: string;
  onCtaClick(): void;
}

const OwnerDemo: React.FC<ThankYouWidgetProps> = ({ metaSiteId, biUpgradeReferralClick }) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const props: OwnerDemoProps = React.useMemo(
    () => ({
      title: t('sp.owner-demo.title'),
      body: <Trans i18nKey="sp.owner-demo.text" />,
      cta: t('sp.owner-demo.cta'),
      onCtaClick: () => {
        biUpgradeReferralClick('thank-you-page');
        metaSiteId && openBillingPage(metaSiteId);
      },
    }),
    [t, metaSiteId, biUpgradeReferralClick],
  );
  return React.createElement(isMobile ? OwnerDemoMobile : OwnerDemoDesktop, props);
};

const OwnerDemoMobile: React.FC<OwnerDemoProps> = ({ title, body, cta, onCtaClick }) => {
  return (
    <div data-hook="owner-demo">
      <FocusedHeading as="h2" className={classes.titleMobile} data-hook="status-page-title">
        {title}
      </FocusedHeading>
      <div data-hook="status-page-text" className={classes.textMobile}>
        {body}
      </div>
      <Button
        data-hook="status-page-upgrade"
        className={classes.buttonMobile}
        onClick={onCtaClick}
        priority={ButtonPriority.primary}
        upgrade
      >
        {cta}
      </Button>
    </div>
  );
};

const OwnerDemoDesktop: React.FC<OwnerDemoProps> = ({ title, body, cta, onCtaClick }) => {
  return (
    <div data-hook="owner-demo">
      <FocusedHeading className={classes.titleDesktop} data-hook="status-page-title">
        {title}
      </FocusedHeading>
      <p data-hook="status-page-text" className={classes.textDesktop}>
        {body}
      </p>
      <Button
        data-hook="status-page-upgrade"
        className={classes.buttonDesktop}
        onClick={onCtaClick}
        priority={ButtonPriority.primary}
        upgrade
      >
        {cta}
      </Button>
    </div>
  );
};

interface VerticalSuccessProps extends VerticalStatusContent {
  onCtaClick(): void;
  ctaDisabled?: boolean;
}

const VerticalSuccess: React.FC<ThankYouWidgetProps> = ({
  integrationData,
  navigateFromStatusPage,
  biThankYouPageCtaButtonClick,
  biThankYouPageOnLoad,
  isOrderProvisioned,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  useEffect(() => {
    biThankYouPageOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props: VerticalSuccessProps = React.useMemo(
    () => ({
      titleText: t('sp.vertical.success-title'),
      buttonText: isOrderProvisioned ? t('sp.vertical.success-action') : t('sp.processing'),
      ...integrationData.verticalStatusContent,
      onCtaClick: () => {
        biThankYouPageCtaButtonClick();
        navigateFromStatusPage();
      },
      ctaDisabled: !isOrderProvisioned,
    }),
    [
      t,
      integrationData.verticalStatusContent,
      navigateFromStatusPage,
      biThankYouPageCtaButtonClick,
      isOrderProvisioned,
    ],
  );
  return React.createElement(isMobile ? VerticalSuccessMobile : VerticalSuccessDesktop, props);
};

const VerticalSuccessMobile: React.FC<VerticalSuccessProps> = ({
  titleText,
  buttonText,
  contentText,
  onCtaClick,
  ctaDisabled,
}) => {
  return (
    <div>
      <FocusedHeading as="h2" data-hook="status-success-vertical-title" className={classes.titleMobile}>
        {titleText}
      </FocusedHeading>
      {contentText && (
        <div data-hook="status-success-vertical-content" className={classes.textMobile}>
          {contentText}
        </div>
      )}
      <Button
        className={classes.buttonMobile}
        onClick={onCtaClick}
        data-hook="status-success-vertical-button"
        disabled={ctaDisabled}
        priority={ButtonPriority.primary}
        upgrade
      >
        {buttonText}
      </Button>
    </div>
  );
};

const VerticalSuccessDesktop: React.FC<VerticalSuccessProps> = ({
  titleText,
  buttonText,
  contentText,
  onCtaClick,
  ctaDisabled,
}) => {
  return (
    <div>
      <FocusedHeading data-hook="status-success-vertical-title" className={classes.titleDesktop}>
        {titleText}
      </FocusedHeading>
      {contentText && (
        <p className={classes.textDesktop} data-hook="status-success-vertical-content">
          {contentText}
        </p>
      )}
      <Button
        className={classes.buttonDesktop}
        onClick={onCtaClick}
        data-hook="status-success-vertical-button"
        disabled={ctaDisabled}
        priority={ButtonPriority.primary}
        upgrade
      >
        {buttonText}
      </Button>
    </div>
  );
};
